export default [{
  header: 'Overview',
},

  {
    title: 'Admin-Users',
    icon: 'UserIcon',
    route: 'apps-users-list',
  },

  {
    title: 'Expert-Users',
    icon: 'UserIcon',
    route: 'apps-experts-list',
  },
  {
    title: 'Affiliate Users',
    icon: 'UserIcon',
    route: 'apps-affiliates-list',
  },

  {
    title: 'Mail Templates',
    icon: 'MailIcon',
    route: 'apps-emailtemplates-list',

  },
  {
    title: 'Clients',
    icon: 'MonitorIcon',
    route: 'apps-clients-list',
  },

  {
    title: 'PDF/Invoice Data',
    icon: 'FileIcon',
    route: 'invoice-data',
  },

  {
    title: 'Datenverwaltung',
    icon: 'DatabaseIcon',
    children: [{
      title: 'Languages',
      route: 'apps-languages-list',
    },

      {
        title: 'Sections',
        route: 'apps-sections-list',
      },

      {
        title: 'Target Groups',
        route: 'apps-targetgroups-list',
      },
      {
        title: 'Expert Topics',
        route: 'apps-experttopics-list',
      },

      {
        title: 'Event Types',
        route: 'apps-event-types-list',
      },
    ],
  },
  {
    title: 'FAQ',
    icon: 'ClipboardIcon',
    children: [
      {
        title: 'Entries',
        route: 'faq-entries-list',
      },

      {
        title: 'Categories',
        route: 'faq-categories-list',
      },
    ],
  },
  {
    title: 'Help Area',
    icon: 'HelpCircleIcon',
    route: 'apps-helps-list',
  },
  {
    title: 'Media Manager',
    icon: 'HelpCircleIcon',
    route: 'apps-media-manager',
  },

]
