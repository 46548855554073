export default [{
  // title: 'Dashboard',
  // icon: 'HomeIcon',
  // tagVariant: 'light-warning',
  // route: 'dashboard-analytics',

  title: 'Dashboard',
  icon: 'HomeIcon',
  tagVariant: 'light-warning',
  route: 'dashboard-card-statistic',




}, ]
